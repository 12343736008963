import React from "react";
import { useEffect } from "react";
import styles from "./PortfolioList.module.css";
import projects from "../../data/PortfolioData";
function PortfolioList() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 mt-4">
            <h2 className="section-title">
              My <span className="text-primary">Projects</span>
            </h2>
          </div>
          {
            projects.map((res) => {
              return (
                <div className="col-md-6 d-flex" key={res.id}>
                  <div className={`card ${styles.portfolioListBox}`}>
                    <h4>{res.title}</h4>
                    <ul className={styles.portfolioListBox__header}>
                      <li>
                        <strong>Team size:</strong> {res.team_size}
                      </li>
                      <li>
                        <strong>Duration:</strong> {res.duration}
                      </li>
                    </ul>
                    <p>
                      <strong>Overview:</strong>
                      <br />
                      {res.objectives}
                    </p>
                    <ul className={styles.features}>
                      {
                      res.features?.map((res1, index) => {
                        return (
                          <li key={index}>{res1}</li>
                        )
                      })
                      }
                    </ul>
                    <p>
                      <strong>Technologies:</strong>
                      <br />
                      {res.tools}
                    </p>
                    <p>
                      <strong>Roles:</strong>
                      <br />
                      {res.roles}
                    </p>
                    <div>
                      <strong>Links: </strong>
                      {
                        res.links.map((link, index) => {
                          return (
                            <span key={link.id}>
                              <a href={link.link}>{link.title}</a>
                              {index + 1 < res.links.length && <span>, </span>}
                            </span>
                          )
                        })
                      }

                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

export default PortfolioList;
