import React from "react";
import { NavLink } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import projects from "../data/PortfolioData";
import PortfolioDetails from "./portfolio/PortfolioDetails";
const Portfolio = () => {
  const [show, setShow] = useState(false);
  const [bodyText, setBodyText] = useState("");
  const [chunkedProjects, setChunkedProjects] = useState([]);
  const handleClose = () => setShow(false);

  const handleShow = (bodyText) => {
    setBodyText(bodyText);
    setShow(true);
  };
  function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  useEffect(()=>{
    const chunkedProjects = sliceIntoChunks(projects, 2)
    setChunkedProjects(chunkedProjects);

  }, []);

  return (
    <div id="portfolio" className="page-section pt-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <h2 className="section-title">
              My <span className="text-primary">PROJECTS</span>
            </h2>
          </div>
          {[...Array(chunkedProjects.length)].map((x, i) => {
            return (
              <div className="col-lg-4 col-md-6 d-flex" key={i}>
                <div className="box portfolio-box">
                  <ul>
                    {chunkedProjects[i].map((res) => {
                      return (
                        <li key={res.id}>
                          <a
                            href="#portfolio"
                            onClick={() => {
                              handleShow(res);
                            }}
                          >
                            {res.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className=" d-flex justify-content-center">
            <NavLink to="/portfolio" className="btn btn-primary">
              VIEW ALL
            </NavLink>
          </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{bodyText.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body><PortfolioDetails project = {bodyText}/></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Portfolio;
