import StarRatings from "react-star-ratings";

const CustomStarRating = () => {
  return (
    <StarRatings
      rating={4}
      starRatedColor="#000"
      isSelectable="false"
      name="rating"
      starDimension="15px"
      starSpacing="2px"
    />
  );
};
export default CustomStarRating;
