import React from "react";
import Nav from "../Nav";
import ToggleMenu from "../ToggleMenu";
import { Outlet } from 'react-router-dom';

export default function Layout() {
  return (
    <div>
      <ToggleMenu />
      <div className="container-fluid d-flex align-content-between">
        <Nav />
        <main className="main-content">
          <Outlet />
        </main>
      </div>
    </div>
  );
}
