import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { useContext } from "react";
import { NavContext } from "../contexts/MainLayoutProvider";

const Nav = () => {
  const { navLogics } = useContext(NavContext);

  return (
    <nav
      className={`${"left-nav animate__animated"} ${
        navLogics.isMobileMenuOpened
          ? "main-nav_mobile_toggle__visible animate__slideInLeft"
          : navLogics.isFirstTime
          ? ""
          : "main-nav_mobile_toggle__visible animate__slideOutLeft"
      }`}
    >

      <div className="profile d-flex align-items-center flex-column">
        <a href="/" className="mt-4">
          <img className="profile-photo" src="./images/avatar-bw.png" alt="" />
        </a>
        <h5 className="mt-3">Reazul Islam</h5>
      </div>
      <ul className="menu-list">
        <li>
          <HashLink to="/#home">
            <i className="fas fa-home"></i>
            <span>Home</span>
          </HashLink>
        </li>
        <li>
          <HashLink to="/#about">
            <i className="fas fa-info-circle"></i>
            <span>About</span>
          </HashLink>
        </li>
        {/* <li>
          <HashLink to="/#services">
            <i className="fas fa-business-time"></i>
            <span>Services</span>
          </HashLink>
        </li> */}
        <li>
          <HashLink to="/#resume">
            <i className="fas fa-book-open"></i>
            <span>Resume</span>
          </HashLink>
        </li>
        <li>
          <NavLink to="/portfolio" activeclassname="active">
            <i className="fas fa-layer-group"></i>
            <span>Portfolio</span>
          </NavLink>
        </li>
        {/* <li>
          <HashLink to="/#blog">
            <i className="fas fa-blog"></i>
            <span>Blog</span>
          </HashLink>
        </li> */}
        <li>
          <HashLink to="/#contact">
            <i className="fas fa-envelope"></i>
            <span>Contact</span>
          </HashLink>
        </li>
      </ul>
    </nav>
  );
};
export default Nav;
