import CustomStarRating from "./Utility/CustomStarRating";

const Resume = () => {
  const languages = [
    {
      id: Math.random(1, 100),
      title: "PHP",
      rating: 4.5,
    },
    {
      id: Math.random(1, 100),
      title: "Python",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Laravel",
      rating: 4.5,
    },
    {
      id: Math.random(1, 100),
      title: "MySQL",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "PostgreSQL",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Codeigniter",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "HTML, CSS",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Javascript",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Angular",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "ReactJS",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "React Native",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Vuejs",
      rating: 4,
    },
  ];
  const devTools = [
    {
      id: Math.random(1, 100),
      title: "Docker",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "VPS",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Payment Gateway",
      rating: 4.5,
    },
    {
      id: Math.random(1, 100),
      title: "Jira, Scrum, Git",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Ubuntu, CentOS",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Digital Ocean",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "Google Cloud",
      rating: 4,
    },
    {
      id: Math.random(1, 100),
      title: "AWS",
      rating: 4,
    },
  ];
  return (
    <div id="resume" className="page-section pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="section-title">
              My <span className="text-primary">Resume</span>
            </h2>
          </div>
          <div className="col-md-6 d-flex">
            <div className="box">
              <div className="box_title">
                <h4>
                  <i className="fas fa-briefcase"></i> <span>Experience</span>
                </h4>
              </div>
              <div className="box_details">
                <h5>Sr. Software Engineer</h5>
                <p className="institute">Sadagar Ltd</p>
                <p>
                  <label>Roles:</label> Analysis, CI/CD, Architecture Design, Coding
                </p>
                <p>
                  <label>Tools & Technologies:</label> Angular, Ionic, VueJs, Laravel, MySQL, Redis, Firebase, Docker,
                  Google Cloud, Cloudinary, Mailgun
                </p>
              </div>
              <div className="box_details">
                <h5>Software Engineer</h5>
                <p className="institute">Ackosoft Ltd</p>
                <p>
                  <label>Roles:</label> Analysis, Database design, Coding
                </p>
                <p>
                  <label>Tools & Technologies:</label> Angular, Laravel, MySQL, AWS, Gihub, Jira
                </p>
              </div>
              <div className="box_details">
                <h5>Jr. Software Engineer</h5>
                <p className="institute">Unitech Engineer's Group</p>
                <p>
                  <label>Roles:</label> Requirements analysis, Fullstack Coding
                </p>
                <p>
                  <label>Tools & Technologies:</label> Codeignitor, Laravel, MySQL, PostgreSQL
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="box">
              <div className="box_title">
                <h4>
                
                  <i className="fas fa-graduation-cap"></i> <span>Education</span>
                </h4>
              </div>
              <div className="box_details">
                <h5>MBA in MIS</h5>
                <p className="institute">Prime University</p>
                <p>Topics: Accounting, Management, Marketing, Finance, MIS etc.</p>
              </div>
              <div className="box_details">
                <h5>BSc in CSE</h5>
                <p className="institute">Daffodil International University</p>
                <p>Topics: Assembly, Java, Alogorithm, Database, Networking, Mictrocontroller, System analysis etc.</p>
              </div>
              <div className="box_details">
                <h5>Diploma in CST</h5>
                <p className="institute">Feni Computer Institute</p>
                <p>Topics: C, C++, Database, Microprocessor, Networking etc.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex">
            <div className="box w-100">
              <div className="box_title">
                <h4>
                  <i className="fas fa-code"></i> <span>Languages, Frameworks</span>
                </h4>
              </div>
              <div className="row">
                <ul className="col-6 resume-rating">
                  {languages.slice(0, languages.length / 2).map((lang) => {
                    return (
                      <li key={lang.id}>
                        <span className="title">{lang.title}</span>
                        <CustomStarRating />
                      </li>
                    );
                  })}
                </ul>
                <ul className="col-6 resume-rating">
                  {languages.slice(languages.length / 2, languages.length).map((lang) => {
                    return (
                      <li key={"another" + lang.id}>
                        <span className="title">{lang.title}</span>
                        <CustomStarRating />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="box w-100">
              <div className="box_title">
                <h4>
                  <i className="fas fa-cloud"></i> <span>Dev tools & Others</span>
                </h4>
              </div>
              <div className="row">
                <ul className="col-6 resume-rating">
                  {devTools.slice(0, devTools.length / 2).map((lang) => {
                    return (
                      <li key={lang.id}>
                        <span className="title">{lang.title}</span>
                        <CustomStarRating />
                      </li>
                    );
                  })}
                </ul>
                <ul className="col-6 resume-rating">
                  {devTools.slice(devTools.length / 2, devTools.length).map((lang) => {
                    return (
                      <li key={"another" + lang.id}>
                        <span className="title">{lang.title}</span>
                        <CustomStarRating />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
