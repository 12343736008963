import NotFound from "./components/NotFound";
import PortfolioList from "./components/portfolio/PortfolioList";
import Home from "./components/_layouts/Home";
import Layout from "./components/_layouts/Layout";

export const routes = [
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Home /> },
        {
          path: "portfolio",
          element: <PortfolioList />,
        },
        { path: "*", element: <NotFound /> }
      ]
    }
  ];