import React from 'react';

const Intro = () => {
    return (
        <div id="home" className="home-section vh-100" style={{backgroundImage: `url('./images/home-bg.jpeg')`}}>
            <div className="container" style={{zIndex: 3}}>
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="home-content animate__animated animate__zoomIn">
                    <img className="profile-photo rounded-circle" style={{height: '130px', width: '130px'}} src="./images/avatar-bw.png" alt=""/>
                    <h1 className="heading mt-2 animate__animated animate__zoomIn">Reazul Islam</h1>
                    <p>Full stack developer(React, Angular, Vue, PHP)</p>
                    <div className="social-links">
                      <a href="/"><i className="fab fa-facebook-f"></i></a>
                      <a href="/"><i className="fab fa-twitter"></i></a>
                      <a href="/"><i className="fab fa-linkedin"></i></a>
                      <a href="/"><i className="fab fa-youtube"></i></a>
                    </div>
                    <div className="about-links">
                      <a href="#contact" className="btn btn-primary mr-10">CONTACT ME</a>
                      <a href="#about" className="btn btn-primary">INTRO</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    );
};

export default Intro;
