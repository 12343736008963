import { createContext, useState } from 'react';
export const NavContext = createContext();
export const NavProvider = props => {
    const [navLogics, setNavLogics] = useState({
        isFirstTime: true,
        isMobileMenuOpened: false
    })
    return (
        <NavContext.Provider value={{navLogics, setNavLogics}}>
            {props.children}
        </NavContext.Provider>
    )
}