import React from 'react';

const About = () => {
    return (
        <div id="about" className="page-section pt-5">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <h2 className="section-title">ABOUT <span className="text-primary">ME</span></h2>
                </div>
              </div>
              <div className="row">
              <div className="col-lg-4">
                <div className="profile-img-sq">
                  <img src="./images/avatar-bw-potrait.png" alt="Reazul"/>
                </div>
              </div>
              <div className="col-lg-8 align-self-center">
                <div className="description">
                  <p>
                  Quality-driven, Well-organized, and Hands-on software engineering experience with a
                   passion for developing innovative applications that accelerate the efficiency and 
                   effectiveness of organizational success.A skilled leader who has the proven ability 
                   to motivate, educate, and manage a team of professionals to build software solutions 
                   and effectively track changes. <br/><br/>
                   <strong>I love to do</strong> Database Design, Business requirements to technical requirements analysis, Backend coding, Team leading.
                  </p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="about-me__content">
                  <ul>
                    <li>
                      <span><label>City:</label> Dhaka, Bangladesh</span>
                    </li>
                    <li>
                      <span><label>Interests:</label> Hiking, Riding</span>
                    </li>
                    <li>
                      <span><label>Email:</label> <a href="mailto:contact@reazuls.com"> contact@reazuls.com</a></span>
                    </li>
                    <li>
                      <span><label>LinkedIn:</label> <a href="https://www.linkedin.com/in/reazul-islam-bd/"> Reazul Islam</a></span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="container mt-4 mb-4">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="box">
                      <img style={{maxWidth: '80px'}}
                        src="https://geniusdevs.com/themeforest/gfolio/v3-one/assets/images/icon/005-target.png" alt=""/>
                      <div className="counter">100+</div>
                      <p>Project done</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="box">
                      <img style={{maxWidth: '80px'}}
                        src="https://geniusdevs.com/themeforest/gfolio/v3-one/assets/images/icon/002-medical-mask.png"
                        alt=""/>
                      <div className="counter">100+</div>
                      <p>Project done</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="box">
                      <img  style={{maxWidth: '80px'}}
                        src="https://geniusdevs.com/themeforest/gfolio/v3-one/assets/images/icon/053-success-1.png"
                        alt=""/>
                      <div className="counter">100+</div>
                      <p>Project done</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="box">
                      <img  style={{maxWidth: '80px'}}
                        src="https://geniusdevs.com/themeforest/gfolio/v3-one/assets/images/icon/045-hacker.png" alt=""/>
                      <div className="counter">100+</div>
                      <p>Project done</p>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
            </div>
          </div>
    );
};

export default About;