import styles from "./PortfolioList.module.css";
const PortfolioDetails = ({ project }) => {
  return (
    <>
      <ul className={styles.portfolioListBox__header}>
        <li>
          <strong>Team size:</strong> {project.team_size}
        </li>
        <li>
          <strong>Duration:</strong> {project.duration}
        </li>
      </ul>
      <p>
        <strong>Overview:</strong>
        <br />
        {project.objectives}
      </p>
      <ul className={styles.features}>
        {project.features?.map((res1, index) => {
          return <li key={index}>{res1}</li>;
        })}
      </ul>
      <p>
        <strong>Technologies:</strong>
        <br />
        {project.tools}
      </p>
      <p>
        <strong>Roles:</strong>
        <br />
        {project.roles}
      </p>
      <div>
        <strong>Links: </strong>
        {project.links.map((link, index) => {
          return (
            <span key={link.id}>
              <a href={link.link}>{link.title}</a>
              {index + 1 < project.links.length && <span>, </span>}
            </span>
          );
        })}
      </div>
    </>
  );
};
export default PortfolioDetails;
