import React from "react";
import { useState } from "react";
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [btnLoader, setBtnLoader] = useState("SEND MESSAGE");
  const [responseMsg, setResponseMsg] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnLoader("Sending...");
    setResponseMsg("");
    setIsDisabled(true);

    let formData = {
      name: name,
      email: email,
      subject: subject,
      description: description,
    };
    formData = new URLSearchParams(formData).toString();
    fetch("https://api.reazuls.com/email/contact.php?" + formData, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setBtnLoader("SEND MESSAGE");
          setIsDisabled(false);
          setResponseMsg(result.message);
          setName("");
          setEmail("");
          setSubject("");
          setDescription("");
        },
        (error) => {
          setIsDisabled(false);
          setResponseMsg(error.message);
        }
      );
  
};
  return (
    <div id="contact" className="page-section pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="section-title">
              Contact <span className="text-primary">Me</span>
            </h2>
          </div>
          <div className="col-md-6 d-flex">
            <div className="box p-2">
              <form
                className="contact-form"
                autoComplete="off"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="full_name"
                        className="form-control"
                        placeholder="Enter your name*"
                        required="required"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email*"
                        required="required"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        placeholder="Subject*"
                        required="required"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        rows="5"
                        placeholder="Enter your message"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-primary w-100"
                      disabled={isDisabled}
                    >
                      {btnLoader}
                    </button>
                  </div>
                  <p>{responseMsg}</p>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="box p-2">
              <iframe
                title="My Location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d116834.15093165191!2d90.34928594277841!3d23.780620653412853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8b087026b81%3A0x8fa563bbdd5904c2!2sDhaka!5e0!3m2!1sen!2sbd!4v1628309057833!5m2!1sen!2sbd"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
