import "./scss/app.scss";
import { NavProvider } from "./contexts/MainLayoutProvider";
import { useRoutes } from "react-router-dom";
import { routes } from "./Routes";
const App = () => {
  let element = useRoutes(routes);

  return (
    <NavProvider>
      {element}
    </NavProvider>
  );
}

export default App;
