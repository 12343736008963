import { useContext } from "react";
import { NavContext } from "../contexts/MainLayoutProvider";
export default function ToggleMenu(props) {
  const { navLogics, setNavLogics } = useContext(NavContext);

  const toggleMobileMenu = () => {
    setNavLogics({ ...navLogics, isMobileMenuOpened: !navLogics.isMobileMenuOpened });
  };
  const toggleIconClosed = () => {
    setNavLogics({...navLogics,  isMobileMenuOpened: true, isFirstTime: false });
  };

  return (
    <div className="menu-toogle-icon">
      <div
        className={` ${"menu-toogle-icon__open"} ${navLogics.isMobileMenuOpened ? "d-none" : ""}`}
        onClick={() => toggleIconClosed()}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        className={` ${"menu-toogle-icon__close"} ${navLogics.isMobileMenuOpened ? "d-block" : ""}`}
        onClick={() => toggleMobileMenu()}
      >
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
