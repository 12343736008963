import React from "react";
import About from "../About";
import Blog from "../Blog";
import Contact from "../Contact";
import Intro from "../Intro";
import Overlay from "../Overlay";
import Portfolio from "../Portfolio";
import Resume from "../Resume";
import Service from "../Service";

export default function Home() {
  return (
    <>
      <Overlay />
      <Intro/>
      <About />
      {/* <Service /> */}
      <Resume />
      <Portfolio />
      {/* <Blog /> */}
      <Contact />
    </>
  );
}
