import React from "react";
import { useContext } from "react";
import { NavContext } from "../contexts/MainLayoutProvider";
export default function Overlay() {
  const { navLogics, setNavLogics } = useContext(NavContext);

  const toggleMobileMenu = () => {
    setNavLogics({ ...navLogics, isMobileMenuOpened: !navLogics.isMobileMenuOpened });
  };
  
  return (
    <div
      className={` ${"overlay"} ${navLogics.isMobileMenuOpened ? "d-block" : ""}`}
      onClick={() => toggleMobileMenu()}
    ></div>
  );
}
