let data = [
    {
      id: Math.random(),
      title: 'Multi Vendor E-commerce',
      team_size: 4,
      duration: '6 months',
      objectives: 'A multi vendor e-commerce system where a user can be either personal or business seller. They can independently manage their shop. Seller and marketplace both can fullfillment their shipment according to the policy.',
      features: [
        'Shop based coupon',
        'Delivery charge according to shop location',
        'Messaging with Seller',
        'Multi layer category',
        'Category wise comission',
        'Different types of reports: Category, Product, Top selling product, Date wise sale etc.'
      ],
      tools: 'Angular, Laravel, MySQL, Firebase, Mailgun, GCP',
      roles: 'Requirements analysis, Database design, Backend Coding',
      links: [
        {
          id: Math.random(),
          link: 'https://sadagar.com',
          title: 'Sadagar.com'
        }
      ]
    },
    {
      id: Math.random(),
      title: 'Single Vendor E-commerce',
      team_size: 4,
      duration: '3 months',
      objectives: `A single vendor e-commerce system where the business owner manipulate data and manage the team.
                  Admin can manage and create custom invoice and send customer to payment link.
      `
      ,
      features: [
        'Occasion wise coupon',
        'Variations according to colour & size',
        'Multi layer category',
        'Workflow management',
        'Paypal, Card payment'
      ],
      tools: 'Angular, Laravel, MySQL, Hostwind VPS',
      roles: 'Requirements analysis, Database design, API development',
      links: [
        {
          id: Math.random(),
          link: 'https://rippertshirts.co.uk',
          title: 'RipperTshirts'
        },
        {
          id: Math.random(),
          link: 'https://printbritannia.com',
          title: 'Printbritannia'
        }
      ]
    },
    {
      id: Math.random(),
      title: 'Education Management System',
      team_size: 3,
      duration: '6 months',
      objectives: `Primary, secondary, Higher secondary level educational institution can manage
                    their all educational activities throughout the system`,
      features: [
        'Admission, Attendance, Result',
        'Fees collection',
        'HR management',
        'Multi branch management',
        'Progress report, Transfer Certificate'
      ],
      tools: 'JQuery, Laravel, MySQL',
      roles: 'Database design assistant, Backend Coding',
      links: [
        {
          id: Math.random(),
          link: 'https://falahiafeni.edu.bd',
          title: 'FalahiaMadrasha'
        }
      ]
    },
    {
      id: Math.random(),
      title: 'Job portal',
      team_size: 3,
      duration: '4 months',
      objectives: `Job seeker can manage their resume and apply for job easily and 
                  Employer also publish a job and manage applicants through this system.`,
      features: [
        'Job seeker\'s resume management',
        'Job application, Favourite employer, job favourite',
        'Communication with applicants',
        'CV bank subscription and category wise CV view',
        'Tender, Training, Event, Job fair, Admission news'
      ],
      tools: 'Angular, JQuery, Laravel, MySQL',
      roles: 'Database design, Frontend coding, API coding',
      links: [
        {
          id: Math.random(),
          link: 'https://morejobsbd.net',
          title: 'MorejobsBD'
        }
      ]
    },
    {
      id: Math.random(),
      title: 'Bookkeeping & Accounting System (SAAS)',
      team_size: 5,
      duration: '4 months',
      objectives: `Accounting professionals as well as general users can manage their bookkeeping using this system.
                    Double entry system and American accounting standards followed there.`,
      features: [
        'Software as a service concept used, SSO (Single sign on)',
        'Dynamic Chart of accounts, Multiple currency',
        'Reports: Balance sheet, Journal, Ledger, Trial balance, Profit & Loss, Inventory, Transactions',
        'Bills, Invoice, Manual journal create and send to client',
        'Contacts wise report generate and send to clients',
        'Settings: Dynamic Invoice pattern, Email template, Dynamic roles and user management',
      ],
      tools: 'Angular, Laravel, MySQL',
      roles: 'Database design, SSO Application architecture, Auth API design',
      links: [
        {
          id: Math.random(),
          link: 'https://accounting.sadagar.com',
          title: 'SAS360'
        }
      ]
    },
    {
      id: Math.random(),
      title: 'Asset management system ',
      team_size: 2,
      duration: '4 months',
      objectives: `A business can manage their assets using this system.`,
      features: [
        'Dynamic asset locations, building',
        'Import & export data in csv/excel format',
        'Asset tracking, Asset label generate',
        'Asset transfer to different locations',
        'Budget management',
        'Reports: PieChart, Graphs, Financial report Export to PDF, CSV'
      ],
      tools: 'JQuery, Laravel, MySQL',
      roles: 'Database design, Backend coding, Report generate',
      links: [
        {
          id: Math.random(),
          link: 'https://oosool.com',
          title: 'OOSOOL'
        }
      ]
    },
  ];
  export default data;